/**
 * FMPEvents.emit
 * @license MIT
 * Adopted from Google MDC method
 * Fires a cross-browser-compatible custom event from the component root of
 * the given type, with the given data.
 * @param {String} evtType event type
 * @param {Object} evtData Data for event.detail
 * @param {Boolean} shouldBubble
 * @param {HTMLElement} [target=document] event target
 */
export const emit = (evtType, evtData, shouldBubble, target = document) => {
  if (shouldBubble === void 0) {
    shouldBubble = false;
  }
  let evt;
  if (typeof CustomEvent === 'function') {
    evt = new CustomEvent(evtType, {
      bubbles: shouldBubble,
      detail: evtData,
    });
  } else {
    evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(evtType, shouldBubble, false, evtData);
  }
  target.dispatchEvent(evt);
};

/**
 * FMPEvents.listen
 * @license MIT
 * Adopted from Google MDC method
 * Add an event listener
 * @param  {string} evtType event type
 * @param  {Function} handler The object that receives a notification
 *  when an event of the specified type occurs
 * @param  {Object} options options Optional
    An options object specifies characteristics about the event listener.
    The available options are:
      capture
        A Boolean indicating that events of this type will be dispatched to
        the registered listener before being dispatched to any EventTarget
        beneath it in the DOM tree.
      once
        A Boolean indicating that the listener should be invoked at most once
        after being added. If true, the listener would be automatically
        removed when invoked.
      passive
        A Boolean that, if true, indicates that the function specified by
        the listener will never call preventDefault().
        If a passive listener does call preventDefault(),
        the user agent will do nothing other than generate a console warning.
  * @param {HTMLElement} [target=document] The event target
 */
export const listen = (evtType, handler, options, target = document) => {
  target.addEventListener(evtType, handler, options);
};

/**
 * FMPEvents.unlisten
 * @license MIT
 * Adopted from Google MDC method
 * Remove an event listener
 * @param  {string} evtType event type
 * @param  {Function} handler The object that receives a notification
 *  when an event of the specified type occurs
 * @param  {Object} options options Optional
    An options object specifies characteristics about the event listener.
    The available options are:
      capture
        A Boolean indicating that events of this type will be dispatched to
        the registered listener before being dispatched to any EventTarget
        beneath it in the DOM tree.
      once
        A Boolean indicating that the listener should be invoked at most once
        after being added. If true, the listener would be automatically
        removed when invoked.
      passive
        A Boolean that, if true, indicates that the function specified by
        the listener will never call preventDefault().
        If a passive listener does call preventDefault(),
        the user agent will do nothing other than generate a console warning.
  * @param {HTMLElement} [target=document] The event target
*/
export const unlisten = (evtType, handler, options, target = document) => {
  target.removeEventListener(evtType, handler, options);
};
